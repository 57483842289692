import React, { useEffect, useRef, useState } from "react";
import CustomFileInput from "../reuseable/CustomFileInput";
import CustomInput from "../reuseable/CustomInput";
import CategoryMoal from "./CategoriesModl";
import { myFeach, myPutFeach } from "../../api";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
import PageLoader from "../reuseable/PageLoader";
import { compareObjects } from "../../api/Helpers";
export default function AddBookModal({
  closeModal,
  regetBooks,
  isEdit,
  bookId,
}) {
  const [bookOldData, setbookOldData] = useState({});
  const [bookData, setbookData] = useState({
    bookPath: "",
    imagePath: "",
    title: "",
    author: "",
    brief: "",
    categories: [],
    publisingHouse: "",
    publishedAt: "",
    numberOfParts: "",
  });
  const [inputsErr, setinputsErr] = useState({});
  const [categorys, setcategorys] = useState({
    loader: { status: "loading", text: "" },
    data: [],
  });
  const [openCategoryMoal, setopenCategoryMoal] = useState({ status: false });
  const [getOldDataLoader, setgetOldDataLoader] = useState({
    status: isEdit ? "loading" : "done",
    text: "جاري تحميل بيانات الكتاب",
  });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [sucsessMsg, setsucsessMsg] = useState(false);
  // handle get all categories to show on dropdwoen
  const getCategories = () => {
    if (categorys.loader.status !== "loading") {
      setcategorys({
        ...categorys,
        loader: { status: "loading", text: "جاري تحميل التصنييفات" },
      });
    }
    myFeach(`categories`, "GET")
      .then(({ data }) => {
        setcategorys({
          loader: {
            status: data.categories.length == 0 ? "failed" : "done",
            text: data.categories.length == 0 ? "لا يوجد تصنيفات" : "",
          },
          data: data.categories,
        });
      })
      .catch((err) => {
        console.log(err);
        setcategorys({ loader: { status: "failed", text: "" }, data: [] });
      });
  };
  // handle get book old data if its edit page
  const getBookOldData = () => {
    if (getOldDataLoader.status !== "loading") {
      setgetOldDataLoader({
        status: "loading",
        text: "جاري تحميل بيانات الكتاب",
      });
    }
    myFeach(`books/${bookId}`, "GET")
      .then(({ data }) => {
        const formatedBookData = {
          bookPath: data.bookPath,
          imagePath: data.imagePath,
          title: data.title,
          author: data.author.fullName,
          brief: data.brief,
          // need update
          categories: data.categories
            ? [data?.categories[0]]
            : [categorys.data[0].id],
          publisingHouse: data.publisingHouse,
          publishedAt: data.publishedAt,
          numberOfParts: data.numberOfParts,
        };
        setbookData({ ...formatedBookData });
        setbookOldData({ ...formatedBookData });
        setgetOldDataLoader({ status: "done", text: "" });
      })
      .catch((err) => {
        console.log(err);
        setgetOldDataLoader({
          status: "failed",
          text: "فشل تحميل بيانات الكتاب",
        });
      });
  };
  // handle on change inputs
  const handleOnchange = (e) => {
    setbookData({ ...bookData, [e.target.name]: e.target.value });
  };
  // handle submit book if its edit or add book
  function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  }
  const checkReqInputs = () => {
    let myinputsErr;
    for (let key in bookData) {
      if (!bookData[key]) {
        myinputsErr = { ...myinputsErr, [key]: "هذا الحقل مطلوب" };
      }
    }
    setinputsErr({ ...myinputsErr });
    if (myinputsErr) {
      return false;
    }
  };
  const handleAddBook = () => {
    setsubmitLoader({ status: "loading", text: "" });
    myFeach(`books`, "POST", bookData, "authrized")
      .then((res) => {
        setsubmitLoader({ status: "done", text: "" });
        regetBooks();
        setsucsessMsg(true);
        const selectElement = document.getElementById("mySelect");
        if (selectElement) {
          selectElement.selectedIndex = 0;
        }
        setbookData({});
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "" });
        console.log("err", err);
        let myInputsErr = JSON.parse(err.message).errors;
        setinputsErr({ ...myInputsErr });
      });
  };
  const handleEditBook = () => {
    setsubmitLoader({ status: "loading", text: "" });
    myPutFeach(`books/${bookId}`, compareObjects(bookData, bookOldData))
      .then((data) => {
        setsubmitLoader({ status: "done", text: "" });
        regetBooks();
        setsucsessMsg(true);
        getBookOldData();
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "فشل تعديل الكتاب" });
        console.log("err", err);
        let myInputsErr = JSON.parse(err.message).errors;
        setinputsErr({ ...myInputsErr });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkReqInputs() === false) {
      console.log("there is empty");
      return;
    } else {
      if (isEdit) {
        handleEditBook();
      } else {
        handleAddBook();
      }
    }
    // checkReqInputs();
  };
  const checkIfCatSelectHasValue = () => {
    if (isEdit && bookData.categories[0]) {
      return true;
    } else {
      return false;
    }
  };
  const handleSelectCategory = (e) => {
    if (e.target.value == "false") {
      setbookData({ ...bookData, categories: [] });
    } else {
      setbookData({
        ...bookData,
        categories: [e.target.value],
      });
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    if (isEdit) {
      getBookOldData();
    }
  }, []);
  return (
    <div className="modal_container">
      {/* modales */}
      {openCategoryMoal.status && (
        <CategoryMoal
          categorys={categorys.data}
          closeModal={() => setopenCategoryMoal({ status: false })}
          regetCategorys={getCategories}
          getCategoriesLoader={categorys.loader}
        />
      )}
      {sucsessMsg && (
        <SucsessMessageModal
          close={() => setsucsessMsg(false)}
          text={isEdit ? "تم تعديل الكتاب" : "تم إضافة الكتاب"}
        />
      )}
      {/* end modals */}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal_year_detailes">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content">
        {getOldDataLoader.status === "done" ? (
          <form onSubmit={handleSubmit}>
            <div className="inputs w-10/12">
              <div className="mt-8 w-10/12">
                <CustomFileInput
                  title="قم برفع الكتاب بحد أقصى 100MB"
                  img="https://i.postimg.cc/sDss4GtK/documenttext1.jpg"
                  errours={inputsErr}
                  name="bookPath"
                  onchangeFun={
                    (url) => setbookData({ ...bookData, bookPath: url })
                    // bookData={...bookData,bookPath:url}
                  }
                  uplodePath="books/pdf"
                  addedImage={bookData.bookPath}
                  isFile={true}
                  isEdit={{
                    status: isEdit,
                    path: "https://i.postimg.cc/T2ztjz2w/done.png",
                  }}
                />
              </div>
              <div className="mt-8 w-10/12">
                <CustomFileInput
                  addedImage={bookData.imagePath}
                  title="قم برفع الصورة بحد أقصى 2MB"
                  img="https://i.postimg.cc/3J8fqYnd/imge.png"
                  errours={inputsErr}
                  name="imagePath"
                  onchangeFun={
                    (url) => setbookData({ ...bookData, imagePath: url })
                    // bookData={...bookData,imagePath:url}
                  }
                  uplodePath="books/image"
                  isFile={false}
                  isEdit={{ status: isEdit, path: bookData.imagePath }}
                />
              </div>
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="عنوان الكتاب"
                name="title"
                errours={inputsErr}
                value={bookData.title}
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="اسم الكاتب"
                name="author"
                errours={inputsErr}
                value={bookData.author}
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="نبذة عن الكتاب"
                name="brief"
                errours={inputsErr}
                value={bookData.brief}
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="سنة النشر "
                type="number"
                name="publishedAt"
                errours={inputsErr}
                value={bookData.publishedAt}
                // min="1500"
                max={getCurrentYear()}
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="دار النشر"
                name="publisingHouse"
                errours={inputsErr}
                value={bookData.publisingHouse}
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="عدد الأجزاء"
                name="numberOfParts"
                errours={inputsErr}
                value={bookData.numberOfParts}
                type="number"
              />
              <div className="w-full">
                <label>اختر التصنيف</label>
                {categorys.loader.status === "done" && (
                  <div className="w-full flex ">
                    <select
                      id="mySelect"
                      defaultValue={
                        checkIfCatSelectHasValue() && bookData.categories[0]._id
                      }
                      className="w-7/12 thin_border h-8"
                      onChange={handleSelectCategory}
                    >
                      <option value={false}></option>
                      {categorys.data.map((elem, index) => (
                        <option
                          key={index}
                          value={elem.id ? elem.id : elem._id}
                        >
                          {elem.name}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="my_rounded bg_success px-3 py-1 mx-2"
                      onClick={() => setopenCategoryMoal({ status: true })}
                    >
                      تعديل التصنيف
                    </button>
                  </div>
                )}
                {categorys.loader.status === "loading" && (
                  <div className="w-7/12 thin_border h-8 flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                )}
                {categorys.loader.status === "failed" && (
                  <div className="w-full flex">
                    <div className="w-7/12 thin_border h-8 flex items-center justify-center">
                      <h4 className="text-red-400 text-xs">
                        {categorys.loader.text}
                      </h4>
                    </div>
                    <button
                      type="button"
                      className="my_rounded bg_success px-3 py-1 mx-2"
                      onClick={() => setopenCategoryMoal({ status: true })}
                    >
                      تعديل التصنيف
                    </button>
                  </div>
                )}
              </div>
            </div>
            <button
              disabled={submitLoader.status === "loading"}
              type="submit"
              className="my_rounded bg_secondary flex justify-center items-center w-full mt-4 py-2 h-12 h3"
            >
              {submitLoader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                "حفظ"
              )}
            </button>
            {submitLoader.status === "failed" && (
              <h4 className="text-xs w-full text-center text-red-400 mt-2">
                {isEdit ? "فشل تعديل الكتاب" : "فشل إضافة الكتاب"}
              </h4>
            )}
          </form>
        ) : (
          <PageLoader loader={getOldDataLoader} text={getOldDataLoader.text} />
        )}
      </div>
    </div>
  );
}
const categories = [
  { title: "التصنيف 1", id: "key 1" },
  { title: "اتصنيف 2", id: "key 2" },
  { title: "التصنيف 3", id: "" },
];
