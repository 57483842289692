// Helper function to compare arrays
export function compareObjects(newObject, oldData) {
  const diffObject = {};

  for (const key in newObject) {
    if (!(key in oldData) || typeof newObject[key] !== typeof oldData[key]) {
      diffObject[key] = newObject[key];
    } else if (
      typeof newObject[key] === "object" &&
      !Array.isArray(newObject[key])
    ) {
      const nestedDiff = compareObjects(newObject[key], oldData[key]);
      if (
        Object.keys(nestedDiff).length > 0 ||
        Object.keys(newObject[key]).length === 0
      ) {
        diffObject[key] = nestedDiff;
      }
    } else if (Array.isArray(newObject[key])) {
      if (!arraysEqual(newObject[key], oldData[key])) {
        diffObject[key] = newObject[key];
      }
    } else if (newObject[key] !== oldData[key]) {
      diffObject[key] = newObject[key];
    }
  }

  return diffObject;
}

// Helper function to compare arrays
function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}
