import React, { useEffect, useState } from "react";
// data
// components
import LibrarySlider from "../components/reuseable/LibrarySlider";
import CustomTable from "../components/reuseable/CustomTable";
import PersonDetailesModal from "../components/data-base/PersonDetailesModal";
import BookDetailsModal from "../components/books/BookDetails";
import AddBookCoverModal from "../components/books/AddBookCoverModal";
// icons
import { BiShow } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import AddBookModal from "../components/books/AddBookModal";
import { myFeach } from "../api";
import Pagenation from "../components/reuseable/Pagination";
import PageLoader from "../components/reuseable/PageLoader";
import Confirm from "../components/reuseable/Confirm";
import SucsessMessageModal from "../components/reuseable/SucsessMessageModal";
export default function Books() {
  const [modal, setmodal] = useState({
    status: false,
    modalType: "",
    id: "",
  });
  const [activeBook, setactiveBook] = useState("");
  const [activeSlider, setactiveSlider] = useState();
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
    isCover: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [slider, setslider] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل إعلانات الكتب" },
  });
  const [booksList, setbooksList] = useState({
    data: [],
    loader: { status: "loading", text: "يتم تحميل الكتب" },
    numperOfPages: "",
  });
  const [searchKey, setsearchKey] = useState("");
  const [page, setpage] = useState(1);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // check modal status
  const checkModal = (modalName) => {
    let result = false;
    if (modal.status === true && modal.modalType === modalName) {
      result = true;
    }
    return result;
  };
  const getSliderData = () => {
    if (slider.loader.status !== "loading") {
      setslider({
        ...slider,
        loader: { status: "loading", text: "جاري تحميل الإعلانات" },
      });
    }
    myFeach("books/slider", "GET")
      .then(({ data }) => {
        if (data?.books?.length == 0) {
          setslider({
            ...slider,
            data: data.books,
            loader: {
              status: "failed",
              text: "لا يوجد أي إعلانات عن كتب لعرضها",
            },
          });
        } else {
          setslider({
            ...slider,
            data: data.books,
            loader: { status: "done", text: "" },
          });
        }
      })
      .catch((err) => {
        console.log("err....", err);
        setslider({
          ...slider,
          loader: { status: "failed", text: "فشل تحميل إعلانات الكتب" },
        });
      });
  };
  const getBooksData = () => {
    if (booksList.loader.status !== "loading") {
      setbooksList({
        ...booksList,
        loader: { status: "loading", text: "جاري تحميل الكتب" },
      });
    }
    myFeach(`books?title=${searchKey} &page=${page}&limit=10`, "GET")
      .then(({ data }) => {
        if (data.books.length == 0) {
          setbooksList({
            ...booksList,
            data: [],
            loader: { status: "failed", text: "لا يوجد كتب لعرضها" },
          });
        } else {
          setbooksList({
            data: data.books,
            loader: { status: "done", text: "" },
            numberOfPages: data.numberOfPages,
          });
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setbooksList({
          ...booksList,
          loader: { status: "failed", text: "فشل تحميل الكتب" },
        });
      });
  };
  // handleD delete book
  const deleteBook = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`books/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم حذف الكتاب بنجاح",
        });
        getBooksData();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف الكتاب" });
      });
  };
  // handleD delete slider
  const deleteSlider = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`books/slider/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم مسح الكتاب بنجاح",
        });
        getSliderData();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف الكتاب" });
      });
  };
  useEffect(() => {
    getSliderData();
  }, []);
  useEffect(() => {
    getBooksData();
  }, [page]);
  return (
    <div className="page_container books_page_container">
      {/* modales */}
      {checkModal("detailes") && (
        <BookDetailsModal
          openDetailesModal={modal}
          setopenDetailesModal={setmodal}
          headers={detailesHeader}
          id={modal.id}
        />
      )}
      {checkModal("cover") && (
        <AddBookCoverModal
          closeModal={() => setmodal({ ...modal, status: false })}
          coverType="book"
          title="كتاب"
          reloadBooksSlider={getSliderData}
        />
      )}
      {checkModal("book") && (
        <AddBookModal
          closeModal={() => setmodal({ ...modal, status: false })}
          regetBooks={getBooksData}
          isEdit={modal.edit}
          bookId={activeBook}
        />
      )}
      {openConfirmModal.status === "confirmDelete" && (
        <Confirm
          close={() => {
            setopenConfirmModal({ status: false, text: "" });
            setconfirmLoader({ status: "confirm", text: "" });
          }}
          text={openConfirmModal.text}
          confirmFun={
            openConfirmModal.isCover
              ? () => deleteSlider(activeSlider)
              : () => deleteBook(activeBook)
          }
          loader={confirmLoader}
        />
      )}
      {openConfirmModal.status === "sucsess" && (
        <SucsessMessageModal
          close={() => setopenConfirmModal({ status: false, text: "" })}
          text={openConfirmModal.text}
        />
      )}
      {/* end madals */}
      {slider.loader.status === "done" ? (
        <LibrarySlider
          handleDelete={(id) => {
            console.log(id);
            setopenConfirmModal({
              id: id,
              status: "confirmDelete",
              text: "أنت على وشك حذف هذا الإعلان هل أنت متأكد؟",
              isCover: true,
            });
            setactiveSlider(id);
          }}
          openAddCoverModal={() =>
            setmodal({ status: true, modalType: "cover" })
          }
          data={slider.data}
          sliderType={"books"}
        />
      ) : (
        <div className="items-center flex flex-col ">
          <PageLoader loader={slider.loader} text={slider.loader.text} />
          {slider.loader.text === "لا يوجد أي إعلانات عن كتب لعرضها" && (
            <button
              className="bg_secondary my_rounded px-6 py-2 mt-2"
              onClick={() => setmodal({ status: true, modalType: "cover" })}
            >
              أضافة إعلان +
            </button>
          )}
        </div>
      )}
      <div className="flex justify-between items-center mt-8">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getBooksData();
          }}
          className="search flex items-center"
        >
          <input
            onChange={(e) => setsearchKey(e.target.value)}
            className="_my_greay_border px-3 py-1 text-2xl"
            type="text"
          />
          <button className="my_rounded h-10 py-2 px-2  bg_secondary mx-2 ">
            <BsSearch color="white" size={25} />
          </button>
        </form>
        <button
          className="bg_secondary my_rounded px-6 py-2"
          onClick={() =>
            setmodal({ modalType: "book", status: true, edit: false })
          }
        >
          أضافة كتاب +
        </button>
      </div>
      {booksList.loader.status === "done" ? (
        <>
          <CustomTable header={tableHeader}>
            {booksList.data.map((elem, index) => (
              <tr key={index} className="border_buttom_greay">
                <td className="flex items-center w-auto">
                  {/* {elem?.title?.includes("http") ? (
                    <a
                      className="hover:text-yellow-500"
                      href={elem.title}
                      target="_blank"
                    >
                      الذهاب إلى الرابط
                    </a>
                  ) : (
                    elem.title
                  )} */}
                  {elem.title}
                </td>
                <td className="w-1/3">{elem.author?.fullName}</td>
                <td className="">
                  <button
                    className="m-1"
                    onClick={() =>
                      setmodal({
                        ...modal,
                        status: true,
                        modalType: "detailes",
                        id: elem.id,
                      })
                    }
                  >
                    <BiShow color="#D59A2C" size={25} />
                  </button>
                  <button
                    className="m-1"
                    onClick={() => {
                      setmodal({ modalType: "book", status: true, edit: true });
                      setactiveBook(elem.id);
                    }}
                  >
                    <FiEdit2 color="#4ACA5B" size={25} />
                  </button>
                  <button
                    className="m-1"
                    onClick={() => {
                      scrollToTop();
                      setopenConfirmModal({
                        id: elem.id,
                        status: "confirmDelete",
                        text: "أنت على وشك حذف هذا الكتاب هل أنت متأكد؟",
                        isCover: false,
                      });
                      setactiveBook(elem.id);
                    }}
                  >
                    <AiFillDelete color="#F61C0D" size={25} />
                  </button>
                </td>
              </tr>
            ))}
          </CustomTable>
          <Pagenation
            setpage={setpage}
            page={page}
            numperOfPages={booksList.numberOfPages}
          />
        </>
      ) : (
        <PageLoader loader={booksList.loader} text={booksList.loader.text} />
      )}
    </div>
  );
}
const tableHeader = [
  { title: "اسم الكتاب", size: "w-auto" },
  { title: "المؤلف", size: "lg" },
  { title: "", size: "sm" },
];
const detailesHeader = [
  { title: "الكاتب", key: "author", class: "h-11" },
  { title: "نبذة", key: "brief", class: "" },
  { title: "تاريخ النشر", key: "publishedAt", class: "h-11" },
  { title: "دار النشر", key: "publisingHouse", class: "h-11" },
  { title: "عدد الأجزاء", key: "numberOfParts", class: "h-11" },
];
